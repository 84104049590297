<template>
    <table-layout :title="whoseSavedEnvironments.mine ? t('MY_SAVED_ENVIRONMENTS_PAGE_TITLE') : t('SAVED_ENVIRONMENTS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        
        <template #filter v-if="!whoseSavedEnvironments.mine">
            <table-filter-section>
                <table-filter-section-item>
                    <organization-filter v-model="filter.organizationId" :organizations="filterOrganizations" data-testing="saved-environments-table-organization-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <user-filter v-model="filter.userId" :users="users" :disabled="!filter.organizationId" :label="t('SAVED_ENVIRONMENTS_USER_FILTER_LABEL')" data-testing="saved-environments-table-user-filter"/>
                </table-filter-section-item>
            </table-filter-section>
        </template>

        <template #table>
            <cr-bulk-action-table-header :selected-count="selected?.length || 0" :page-size="bulkDeletePageSize" :max-items="maxItems" @select-all="onSelectAll" @clear-selection="onUnselectAll" class="bulk-delete-header">
                <template #actions>
                    <cr-bulk-action-button :label="t('SAVED_ENVIRONMENTS_BULK_DELETE_BUTTON_LABEL')" icon="bi-trash" @click="onBulkDeleteSavedEnvironmentsButtonClicked"/>
                </template>
            </cr-bulk-action-table-header>
            <bulk-delete-saved-environments-dialog v-model="showBulkDeleteEnvironmentsDialog" :currentUserId="currentUserId" :mine="whoseSavedEnvironments.mine" :my-environments-map="myEnvironmentsMap" :environment-ids="selected" @confirm="onBulkDeleteConfirmed"/>
            <delete-saved-environment-dialog v-model="showDeleteSavedEnvironmentDialog" :catalog-family="catalogFamily" @confirm="onDeleteSavedEnvironmentConfirmed" data-testing="delete-saved-environment-dialog"/>
            <remove-my-access-dialog v-model="showRemoveMyAccessDialog" :catalog-family="catalogFamily" @confirm="onRemoveMyAccessConfirmed" data-testing="remove-my-access-dialog"/>
            <cr-table v-model:selected="selected" :disabled-select-items="[]" :items="page?.items" :headers="headers" :loading="isLoading" @sort="onSort" actionable  @suggestedNumberOfItems="onSuggestedNumberOfItems" data-testing="saved-environments-table">
                <template v-slot:action="{item}">
                    <cr-table-action-item :item="item" icon="bi-file-earmark" :to="{ name: Route.SavedEnvironment.name, params: { catalogFamilyId: item.id }}" data-testing="saved-environments-table-action-view">
                        {{ t('SAVED_ENVIRONMENTS_TABLE_ACTION_VIEW') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="showTableActionDelete(item)" :item="item" icon="bi-trash" @click="onDeleteClicked(item)" data-testing="saved-environments-table-action-delete">
                        {{ t('SAVED_ENVIRONMENTS_TABLE_ACTION_DELETE') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-else="showTableActionRemoveAccess(item)" :item="item" icon="bi-trash" @click="onRemoveMyAccessClicked(item)" data-testing="saved-environments-table-action-remove-access">
                        {{ t('SAVED_ENVIRONMENTS_TABLE_ACTION_REMOVE_ACCESS') }}
                    </cr-table-action-item>
                </template>
                <template v-slot:metadata="{value}">
                    <cr-tooltip v-for="operatingSystem in useOperatingSystemIcons(value)" :title="capitalCase(operatingSystem.label)">
                        <cr-icon  :key="operatingSystem.value" small :alt="operatingSystem.label" class="cr-pr3">{{operatingSystem.value}}</cr-icon>
                    </cr-tooltip>
                </template>
            </cr-table>
        </template>

        <template #pagination>
            <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="saved-environments-table-pagination"/>
        </template>
   </table-layout>
</template>

<script setup lang="ts">
import  $t  from "../../strings/definitions/savedEnvironmentsStrings";
import "font-logos/assets/font-logos.css"
import * as Claims from '@cyber-range/cyber-range-shared-claims';
import BulkDeleteSavedEnvironmentsDialog from './dialogs/BulkDeleteSavedEnvironmentsDialog.vue';
import DeleteSavedEnvironmentDialog from './dialogs/DeleteSavedEnvironmentDialog.vue';
import OrganizationFilter from '../filters/OrganizationFilter.vue';
import RemoveMyAccessDialog from './dialogs/RemoveMyAccessDialog.vue';
import Route from "../../routers/route";
import TableFilterSection from '../layouts/sections/TableFilterSection.vue';
import TableFilterSectionItem from '../layouts/sections/TableFilterSectionItem.vue';
import TableLayout from '../layouts/TableLayout.vue';
import UserFilter from '../filters/UserFilter.vue';
import { BreadcrumbItem, TableHeaderItem } from "@cyber-range/cyber-range-lib-ui";
import { CatalogFamilyFilter, CatalogFamilySortBy, ICatalogFamily, ICatalogFamilyFilter } from "@cyber-range/cyber-range-api-catalog-client";
import { IApiPageResponse, SortOrder } from '@cyber-range/cyber-range-api-client';
import { IOrganizationUser, OrganizationUserFilter, UserRole, UserStatus } from '@cyber-range/cyber-range-api-user-client';
import { computed, onMounted, ref, toRaw, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useAuthorizationStore } from "../../stores/authorizationStore";
import { useCatalogStore } from '../../stores/catalogStore';
import { useI18n } from 'vue-i18n';
import { useOperatingSystemIcons } from '../../composables/useOperatingSystemIcons';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useNotificationStore } from '../../stores/notificationStore';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { useSavedEnvironmentBreadcrumbs } from "../../composables/useSavedEnvironmentBreadcrumbs";
import { useSelectionStore } from "../../stores/selectionStore";
import { useSessionStore } from '../../stores/sessionStore';
import { useTableLayoutPagination } from '../../composables/useTableLayoutPagination';
import { useToMap } from '../../composables/useToMap';
import { useUserStore } from '../../stores/userStore';
import { useWhoseSavedEnvironment } from '../../composables/useWhoseSavedEnvironment';
import { capitalCase } from "change-case";

const { t } = useI18n();
const { fetchOrganizations } = useOrganizationStore();
const { canDeleteCatalogFamily, canViewOrganizationCatalogFamilies } = useAuthorizationStore();
const { listAllCatalogFamilies } = useCatalogStore();
const { isLoading } = useApiClientStore();
const { organizations } = storeToRefs(useOrganizationStore());

const breadcrumbs = useSavedEnvironmentBreadcrumbs(computed(() => [
    new BreadcrumbItem(Route.SavedEnvironments)
]));

const whoseSavedEnvironments = useWhoseSavedEnvironment();
const myEnvironmentsMap = ref<Map<string, ICatalogFamily>>(new Map());

const selected = ref<string[]>([]);
const selectionStoreKey = `${Route.SavedEnvironments.name}${whoseSavedEnvironments.value.mine ? '#mine': ''}`;
const maxItems = ref<number|undefined>(useSelectionStore().getMaxItems(selectionStoreKey));
const showBulkDeleteEnvironmentsDialog = ref<boolean>(false);

const onBulkDeleteSavedEnvironmentsButtonClicked = async ()=>
{
    showBulkDeleteEnvironmentsDialog.value = true;
    myEnvironmentsMap.value  = useToMap<ICatalogFamily>(await listAllCatalogFamilies({ mine:true }));
};

const onSelectAll = async () =>
{
    const allEnvironments = await useCatalogStore().listAllCatalogFamilies({...toRaw(filter), token: "" });
    selected.value = allEnvironments.map(environment => environment.id)
    maxItems.value = selected.value.length;
}

watch(()=>selected.value, ()=> 
{
    if (selected.value)
    {
        useSelectionStore().setSelected(selectionStoreKey, selected.value);
    }
});

watch(()=>maxItems.value, ()=> 
{
    if (maxItems.value !== undefined)
    {
        useSelectionStore().setMaxItems(selectionStoreKey, maxItems.value);
    }
});

const clearSelected = () =>
{
    selected.value = [];
}

const onUnselectAll = () =>
{
    clearSelected();
}

const bulkDeletePageSize = computed(()=>
{
    return page.value?.items.length || 0;
});

const onBulkDeleteConfirmed = async () =>
{
    clearSelected();
    maxItems.value = undefined;
    page.value = await refresh({token: ''});
}

const canViewSnapshotsInAllOrganizations = canViewOrganizationCatalogFamilies(Claims.Value.ANY);
const filterOrganizations = computed(()=>
{
    return !!canViewSnapshotsInAllOrganizations
        ? organizations.value
        : organizations.value?.filter(organization =>!!canViewOrganizationCatalogFamilies(organization.id))
});

const currentUserId = useSessionStore().session?.userId;

const refresh = async (assignFilter?:Partial<ICatalogFamilyFilter>) =>
{
    const familiesPage = injectCurrentUserOwnershipIndicator(await useCatalogStore().listCatalogFamilies(toRaw({...filter, userId: (filter.organizationId ? filter.userId : undefined), ...assignFilter})));
    if(!!filter.organizationId)
    {
        users.value = await useUserStore().listAllOrganizationUsers(filter.organizationId, new OrganizationUserFilter({roles:[UserRole.CourseAdmin, UserRole.OrganizationInstructor], status: UserStatus.Ready}))
    }
    return familiesPage;
}

const {
    filter,
    onLoadFirstPage,
    onLoadNextPage,
    onLoadPreviousPage,
    onSuggestedNumberOfItems,
    onSort,
    page
} = useTableLayoutPagination(useQueryFilter(CatalogFamilyFilter, { isSnapshot:true, sortBy:CatalogFamilySortBy.Version, sortOrder:SortOrder.Desc, token:'' }), refresh, {clearTokenKeys: ['organizationId']})

watch(() => filter.organizationId, async () =>
{
    clearSelected();
});

watch(() => filter.userId, async () =>
{
    clearSelected();
});

const users = ref<IOrganizationUser[]>([]);

const headers = [
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENTS_TABLE_HEADER_NAME'), key: 'name', sortable: true }),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENTS_TABLE_HEADER_OPERATING_SYSTEMS'), key: 'metadata', sortable: false }),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENTS_TABLE_HEADER_ORGANIZATION_NAME'), key: 'organizationName', sortable: true}),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENTS_TABLE_HEADER_OWNER'), key: 'userName', sortable: true }),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENTS_TABLE_HEADER_VERSION'), key: 'version', sortable: true, sortOrder: SortOrder.Desc })
];

const sortbyHeader = headers.find(h => h.sortable && filter.sortBy === h.key.toLowerCase());
if (sortbyHeader)
{
    sortbyHeader.sortOrder = filter.sortOrder;
}

function injectCurrentUserOwnershipIndicator(catalogFamilyPage:IApiPageResponse<ICatalogFamily>):IApiPageResponse<ICatalogFamily>
{
    catalogFamilyPage.items.forEach(item=>
    {
        if(item.userId === currentUserId)
        {
            item.userName = `${item.userName} ${$t.en.SAVED_ENVIRONMENTS_TABLE_OWNERSHIP_INDICATOR}`;
        }
    });

    return catalogFamilyPage;
}

const showTableActionDelete = (family:ICatalogFamily):boolean =>
{
    if(!!filter.mine)
    {
        return family.userId === currentUserId;        
    }
    else
    {
        return canDeleteCatalogFamily(family,currentUserId);
    }
}

const catalogFamily = ref<ICatalogFamily>();
const showDeleteSavedEnvironmentDialog = ref<boolean>(false);
const onDeleteClicked = (actionableFamily:ICatalogFamily) => 
{
    catalogFamily.value = actionableFamily;
    showDeleteSavedEnvironmentDialog.value = true;
} 
const onDeleteSavedEnvironmentConfirmed = async () =>
{
    showDeleteSavedEnvironmentDialog.value = false;
    page.value = await refresh();
    useNotificationStore().notify(t('SAVED_ENVIRONMENTS_DELETE_NOTIFICATION_TEXT'));
};

const showRemoveMyAccessDialog = ref<boolean>(false);
const onRemoveMyAccessClicked = (actionableFamily:ICatalogFamily) => 
{
    catalogFamily.value = actionableFamily;
    showRemoveMyAccessDialog.value = true;    
};
const onRemoveMyAccessConfirmed = async () =>
{
    showRemoveMyAccessDialog.value = false;
    page.value = await refresh();
    useNotificationStore().notify(t('SAVED_ENVIRONMENTS_REMOVE_MY_ACCESS_NOTIFICATION_TEXT'));
};

watch(filter, (change)=>
{
    if(!change.organizationId && !!change.userId)
    {
        filter.userId = ''
    }
});

onMounted(async ()=>
{
    filter.mine = !!(whoseSavedEnvironments.value?.mine);

    if(!filter.mine && !useOrganizationStore().organizations.length)
    {
        await fetchOrganizations();
    }

    selected.value = useSelectionStore().getSelected(selectionStoreKey);
});
</script>