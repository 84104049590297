import { RouteRecordRaw } from 'vue-router'
import multiguard from 'vue-router-multiguard';
import Page from '../components/Page.vue';
import Todo from '../components/Todo.vue';
import AccountApprovals from '../components/accountApprovals/AccountApprovals.vue';
import Organizations from '../components/organizations/Organizations.vue'
import requireAuth from './guards/requireAuth';
import ssoToCourseware from './guards/ssoToCourseware';
import Course from '../components/courses/Course.vue';
import ExerciseGroupComponent from '../components/exerciseGroups/ExerciseGroup.vue';
import CreateCourse from '../components/courses/CreateCourse.vue';
import EditCourse from '../components/courses/EditCourse.vue';
import findHomeByClaims from './guards/findHomeByClaims';
import CourseUsers from '../components/courses/CourseUsers.vue';
import InvitationCode from '../components/courses/InvitationCode.vue';
import InviteCourseUsers from '../components/courses/InviteCourseUsers.vue';
import ImportCourseUsers from '../components/courses/importUsers/ImportCourseUsers.vue';
import ImportCourseUsersCanvas from '../components/courses/importUsers/ImportCourseUsersCanvas.vue';
import Courses from '../components/courses/Courses.vue';
import BusinessUnit from '../components/businessUnits/BusinessUnit.vue';
import BusinessUnits from '../components/businessUnits/BusinessUnits.vue';
import CreateBusinessUnit from '../components/businessUnits/CreateBusinessUnit.vue';
import EditBusinessUnit from '../components/businessUnits/EditBusinessUnit.vue';
import whoseCourseUrl from './guards/whoseCourseUrl';
import Organization from '../components/organizations/Organization.vue';
import CreateOrganization from '../components/organizations/CreateOrganization.vue';
import EditOrganization from '../components/organizations/EditOrganization.vue';
import enterIf from './guards/enterIf';
import { useAuthorizationStore } from '../stores/authorizationStore';
import CreateExerciseGroup from '../components/exerciseGroups/CreateExerciseGroup.vue';
import CreateExerciseGroupStep2 from '../components/exerciseGroups/CreateExerciseGroupStep2.vue';
import OrganizationUsers from '../components/organizations/OrganizationUsers.vue';
import EditExerciseGroup from '../components/exerciseGroups/EditExerciseGroup.vue';
import InviteOrganizationUser from '../components/organizations/InviteOrganizationUser.vue';
import OrganizationApplications from '../components/organizations/OrganizationApplications.vue';
import CreateOrganizationApplication from '../components/organizations/CreateOrganizationApplication.vue'
import Search from '../components/search/Search.vue';
import Support from '../components/support/Support.vue';
import CyberRange from '../components/guacamole/CyberRange.vue';
import Profile from '../components/profile/Profile.vue';
import CourseApplications from '../components/courses/CourseApplications.vue';
import SyncCourseUsers from '../components/courses/SyncCourseUsers.vue';
import CreateCourseCredentials from '../components/credentials/CreateCourseCredentials.vue';
import enterIfCourseAnonymous from './guards/enterIfCourseAnonymous';
import enterIfCourseIsNotAnonymous from './guards/enterIfCourseIsNotAnonymous';
import newUIClaimCheck from './guards/newUIClaimCheck';
import PilotUnauthorized from '../components/releasePilot/PilotUnauthorized.vue';
import Config from '../config';
import CourseApprovals from '../components/courses/CourseApprovals.vue';
import Billing from '../components/billing/Billing.vue';
import Usages from '../components/usages/Usages.vue';
import Invoice from '../components/billing/Invoice.vue';
import Subscribe from '../components/billing/Subscribe.vue';
import checkCourseAccess from './guards/checkCourseAccess';
import SuperAdmins from '../components/superAdmins/SuperAdmins.vue';
import InviteSuperAdmin from '../components/superAdmins/InviteSuperAdmin.vue';
import AuditLogs from '../components/audit/AuditLogs.vue';
import Plans from '../components/plans/Plans.vue';
import Plan from '../components/plans/Plan.vue';
import Features from '../components/plans/Features.vue';
import Feature from '../components/plans/Feature.vue';
import SavedEnvironment from '../components/savedEnvironments/SavedEnvironment.vue';
import classicUiExerciseGroupCompatibility from './guards/classicUiExerciseGroupCompatibility';
import SavedEnvironments from '../components/savedEnvironments/savedEnvironments.vue';
import checkSavedEnvironmentsAccess from './guards/checkSavedEnvironmentAccess';

class Route
{
    static Home = {name: "home", path: "/", component: Page}
    static Todo = {name: "todo", path: "/todo", component: Todo}
    static Courseware = {name: "Courseware", path: "/courseware"}

    static MyCourses = {name: "My Courses", path: "/my-courses", component: Courses}
    static Courses = {name: "Courses", path: "/courses", component: Courses, mine: Route.MyCourses}

    static MyCourse = {name: "My Course", path: "/my-courses/:courseId", component: Course, props: true}
    static Course = {name: "Course", path: "/courses/:courseId", component: Course, props: true, mine: Route.MyCourse}

    static CreateMyCourse = {name: "Create My Course", path: "/my-courses/new", component: CreateCourse}
    static CreateCourse = {name: "Create a Course", path: "/courses/new", component: CreateCourse, mine: Route.CreateMyCourse}

    static EditMyCourse = {name: "Edit My Course", path: "/my-courses/:courseId/edit", component: EditCourse, props: true}
    static EditCourse = {name: "Edit a Course", path: "/courses/:courseId/edit", component: EditCourse, props: true, mine: Route.EditMyCourse}

    static MyCourseUsers = {name: "My Course Users", path: "/my-courses/:courseId/users", component: CourseUsers, props: true}
    static CourseUsers = {name: "Course Users", path: "/courses/:courseId/users", component: CourseUsers, props: true, mine: Route.MyCourseUsers}

    static MyInvitationCode = {name: "My Course Invitation Code", path: "/my-courses/:courseId/users/invitation-code", component: InvitationCode, props: true}
    static InvitationCode = {name: "Invitation Code", path: "/courses/:courseId/users/invitation-code", component: InvitationCode, props: true, mine: Route.MyInvitationCode}

    static MyInviteCourseUsers = {name: "My Course Invite Users", path: "/my-courses/:courseId/users/invite-users", component: InviteCourseUsers, props: true}
    static InviteCourseUsers = {name: "Invite Users", path: "/courses/:courseId/users/invite-users", component: InviteCourseUsers, props: true, mine: Route.MyInviteCourseUsers}

    static MyImportCourseUsersCanvas = {name: "My Course Canvas Integration", path: "/my-courses/:courseId/users/invite-users/canvas", component: ImportCourseUsersCanvas, props: true}
    static ImportCourseUsersCanvas = {name: "Canvas Integration", path: "/courses/:courseId/users/invite-users/canvas", component: ImportCourseUsersCanvas, props: true, mine: Route.MyImportCourseUsersCanvas}

    static MyImportCourseUsers = {name: "Import My Users", path: "/my-courses/:courseId/users/invite-users/:importMethod", component: ImportCourseUsers, props: true}
    static ImportCourseUsers = {name: "Import Users", path: "/courses/:courseId/users/invite-users/:importMethod", component: ImportCourseUsers, props: true, mine: Route.MyImportCourseUsers}

    static SyncMyCourseUsers = {name: "Sync My Course Users", path: "/my-courses/:courseId/users/sync", component: SyncCourseUsers, props: true}
    static SyncCourseUsers = {name: "Sync Course Users", path: "/courses/:courseId/users/sync", component: SyncCourseUsers, props: true, mine: Route.SyncMyCourseUsers}

    static CreateMyCourseCredentials = {name: "Create My Course Credentials", path: "/my-courses/:courseId/users/create-credentials", component: CreateCourseCredentials, props: true}
    static CreateCourseCredentials = {name: "Create Course Credentials", path: "/courses/:courseId/users/create-credentials", component: CreateCourseCredentials, props: true, mine: Route.CreateMyCourseCredentials}

    static MyCourseExerciseGroup = {name: "My Course Exercise Environment", path: "/my-courses/:courseId/exercise-environments/:exerciseGroupId", component: ExerciseGroupComponent, props: true}
    static ExerciseGroup = {name: "ExerciseGroup", path: "/courses/:courseId/exercise-environments/:exerciseGroupId", component: ExerciseGroupComponent, props: true, mine: Route.MyCourseExerciseGroup}
    static ClassicUiExerciseGroup = {name: "Classic UI's Exercise Environment", path: "/courses/:courseId/exercise-groups/:exerciseGroupId", component: ExerciseGroupComponent,props: true}

    static CreateMyCourseExerciseGroup = {name: "Create My Course Exercise Environment", path: "/my-courses/:courseId/exercise-environments/new", component: CreateExerciseGroup, props: true}
    static CreateExerciseGroup = {name: "Create Exercise Environment", path: "/courses/:courseId/exercise-environments/new", component: CreateExerciseGroup, props: true, mine: Route.CreateMyCourseExerciseGroup}
    static CreateMyCourseExerciseGroupStep2 = {name: "Create My Course Exercise Environment - Step 2", path: "/my-courses/:courseId/exercise-environments/new/:catalogFamilyId", component: CreateExerciseGroupStep2, props: true}
    static CreateExerciseGroupStep2 = {name: "Create Exercise Environment - Step 2", path: "/courses/:courseId/exercise-environments/new/:catalogFamilyId", component: CreateExerciseGroupStep2, props: true, mine: Route.CreateMyCourseExerciseGroupStep2}

    static EditMyCourseExerciseGroup = {name: "Edit My Course Exercise Environment", path: "/my-courses/:courseId/exercise-environments/:exerciseGroupId/edit", component: EditExerciseGroup, props: true}
    static EditExerciseGroup = {name: "Edit Exercise Environment", path: "/courses/:courseId/exercise-environments/:exerciseGroupId/edit", component: EditExerciseGroup, props: true, mine: Route.EditMyCourseExerciseGroup}

    static MyCourseApplications = {name: "My Course Applications", path: "/my-courses/:courseId/applications", component: CourseApplications, props: true}
    static CourseApplications = {name: "Course Applications", path: "/courses/:courseId/applications", component: CourseApplications, props: true, mine: Route.MyCourseApplications}

    static Organizations = {name: "Organizations", path: "/organizations", component: Organizations}
    static Organization = {name: "Organization", path: "/organizations/:organizationId", component: Organization, props: true}
    
    static CreateOrganization = {name: "Create an Organization", path: "/organizations/new", component: CreateOrganization}
    static EditOrganization = {name: "Edit an Organization", path: "/organizations/:organizationId/edit", component: EditOrganization, props: true}

    static Users = {name: "Users", path: "/users", component: OrganizationUsers, props: true}
    static OrganizationUsers = {name: "Organization Users", path: "/organizations/:organizationId/users", component: OrganizationUsers, props: true}

    static Billing = {name: "Billing", path: "/organizations/:organizationId/billing", component: Billing, props: true}

    static OrganizationUsages = {name: "Organization Usages", path: "/organizations/:organizationId/usages", component: Usages, props: true}

    static Invoice = {name: "Invoice", path: "/organizations/:organizationId/billing/invoices/:activityId", component: Invoice, props: true}

    static Subscribe = {name: "Subscribe to a Plan", path: "/organizations/:organizationId/billing/subscribe", component: Subscribe, props: true}

    static InviteUser = {name: "Invite User", path: "/users/invite-user", component: InviteOrganizationUser, props: true}
    static InviteOrganizationUser = {name: "Invite Organization User", path: "/organizations/:organizationId/users/invite-user", component: InviteOrganizationUser, props: true}

    static OrganizationApplications = {name: "Organization Applications", path: "/organizations/:organizationId/applications", component: OrganizationApplications, props: true}
    static CreateOrganizationApplication = {name: "Create Organization Application", path: "/organizations/:organizationId/applications/new", component: CreateOrganizationApplication, props: true}

    static BusinessUnits = {name: "Business Units", path: "/organizations/:organizationId/business-units", component: BusinessUnits, props: true}
    static BusinessUnit = {name: "Business Unit", path: "/organizations/:organizationId/business-units/:businessUnitId", component: BusinessUnit, props: true}
    
    static CreateBusinessUnit = {name: "Create a Business Unit", path: "/organizations/:organizationId/business-units/new", component: CreateBusinessUnit, props: true }
    static EditBusinessUnit = {name: "Edit a Business Unit", path: "/organizations/:organizationId/business-units/:businessUnitId/edit", component: EditBusinessUnit, props: true }

    static Search = {name: "Search", path: "/search", component: Search }

    static ExerciseAccessPoint = { name: "Exercise Access Point", path: "/exercises/:exerciseId/accesspoints/:accessPointId", component: CyberRange, props: true }

    static Support = {name: "Support", path: "/support", component: Support }

    static Profile = {name: "Profile", path: "/profile/:userId?", component: Profile, props: true }

    static PilotUnauthorized = { name:"Pilot", path:"/pilot", component: PilotUnauthorized }

    static Registration =  { path: "/registration/:courseCode?",name: "registration", beforeEnter: () => { window.location.assign(new URL(location.pathname, Config.LOGIN_UI_BASE_URL)); }, component: Page }

    static AccountApprovals =  { path: "/account-approvals/", name: "Account Approvals", component: AccountApprovals };

    static CourseApprovals =  { path: "/course-approvals/", name: "Course Approvals", component: CourseApprovals };

    static SuperAdmins = { path: "/super-admins/", name: "Super Admins", component: SuperAdmins};
    static InviteSuperAdmins = { path: "/super-admins/invite-super-admin", name: "Invite a Super Admin", component: InviteSuperAdmin};

    static Logs = { path: "/logs", name: "Logs", component: AuditLogs };
    
    static Plans = {name: "Plans", path: "/plans", component: Plans, props: true }
    static Plan = {name: "Plan", path: "/plans/products/:productId", component: Plan, props: true };
    static Features = {name: "Features", path: "/plans/addons", component: Features, props: true };
    static Feature = {name: "Feature", path: "/plans/addons/:addonId", component: Feature, props: true };
    static MySavedEnvironments = {name: "My Saved Environments", path: "/my-saved-environments", component: SavedEnvironments}
    static SavedEnvironments = {name: "Saved Environments", path: "/saved-environments", component: SavedEnvironments, mine: Route.MySavedEnvironments}
    static MySavedEnvironment = {name: "My Saved Environment", path: "/my-saved-environments/:catalogFamilyId", component: SavedEnvironment, props: true };
    static SavedEnvironment = {name: "Saved Environment", path: "/saved-environments/:catalogFamilyId", component: SavedEnvironment, props: true, mine: Route.MySavedEnvironment };

    static toRouteRecordRaw():RouteRecordRaw[]
    {
        let routes:RouteRecordRaw[] = [
            {
                path: Route.Home.path,
                component: Page,
                beforeEnter: multiguard([requireAuth, newUIClaimCheck]),
                children: [
                    { ...Route.Home, beforeEnter: findHomeByClaims},
                    Route.Courses,
                    Route.MyCourses,
                    {...Route.Course, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess])},
                    {...Route.MyCourse, beforeEnter: checkCourseAccess },
                    {...Route.EditCourse, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.EditMyCourse, beforeEnter: checkCourseAccess },
                    {...Route.CourseUsers, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.MyCourseUsers, beforeEnter: checkCourseAccess },
                    {...Route.InvitationCode, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess, enterIfCourseIsNotAnonymous]) },
                    {...Route.MyInvitationCode, beforeEnter: multiguard([checkCourseAccess, enterIfCourseIsNotAnonymous]) },
                    {...Route.CreateCourse, beforeEnter: whoseCourseUrl },
                    {...Route.MyInviteCourseUsers, beforeEnter: checkCourseAccess },
                    {...Route.InviteCourseUsers, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.MyImportCourseUsersCanvas, beforeEnter: checkCourseAccess },
                    {...Route.ImportCourseUsersCanvas, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.MyImportCourseUsers, beforeEnter: checkCourseAccess },
                    {...Route.ImportCourseUsers, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.SyncMyCourseUsers, beforeEnter: checkCourseAccess },
                    {...Route.SyncCourseUsers, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.CreateMyCourseCredentials, beforeEnter: multiguard([enterIfCourseAnonymous, checkCourseAccess])},
                    {...Route.CreateCourseCredentials, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess, enterIfCourseAnonymous])},
                    Route.CreateMyCourse,
                    {...Route.MyCourseExerciseGroup, beforeEnter: checkCourseAccess },
                    {...Route.ExerciseGroup, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.ClassicUiExerciseGroup, beforeEnter: classicUiExerciseGroupCompatibility },
                    {...Route.CreateMyCourseExerciseGroup, beforeEnter: checkCourseAccess },
                    {...Route.CreateExerciseGroup, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.CreateMyCourseExerciseGroupStep2, beforeEnter: checkCourseAccess },
                    {...Route.CreateExerciseGroupStep2, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.EditMyCourseExerciseGroup, beforeEnter: checkCourseAccess },
                    {...Route.EditExerciseGroup, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    {...Route.MyCourseApplications, beforeEnter: checkCourseAccess },
                    {...Route.CourseApplications, beforeEnter: multiguard([whoseCourseUrl, checkCourseAccess]) },
                    Route.Organizations,
                    Route.Organization,
                    {...Route.CreateOrganization, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canCreateOrganization}) },
                    {...Route.EditOrganization, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canEditOrganization},["organizationId"]) },
                    {...Route.OrganizationUsers, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewOrganizationUsers},["organizationId"]) },
                    {...Route.Billing, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewBilling},["organizationId"]) },
                    {...Route.Invoice, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewBilling},["organizationId"]) },
                    {...Route.Subscribe, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canUpdateBilling},["organizationId"]) },
                    {...Route.OrganizationUsages, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>useAuthorizationStore().canViewUsages,["organizationId"])},
                    Route.OrganizationApplications,
                    {...Route.CreateOrganizationApplication, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canCreateOrganizationApplication},["organizationId"]) },
                    Route.BusinessUnits,
                    Route.BusinessUnit,
                    {...Route.CreateBusinessUnit, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canManageBusinessUnits})},
                    {...Route.EditBusinessUnit, beforeEnter: (to,from,next)=>enterIf(to,from,next,()=>{ return useAuthorizationStore().canManageBusinessUnits})},
                    Route.Users,
                    Route.InviteUser,
                    Route.InviteOrganizationUser,
                    Route.Search,
                    Route.Support,
                    Route.Profile,
                    {...Route.AccountApprovals, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canApproveInvitations})},
                    {...Route.CourseApprovals, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canApproveCourses})},
                    {...Route.SuperAdmins, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewDevTools})},
                    {...Route.InviteSuperAdmins, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canInviteSuperAdmin})},
                    Route.Logs,
                    {...Route.Plans, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewDevTools})},
                    {...Route.Plan, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewDevTools})},
                    {...Route.Features, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewDevTools})},
                    {...Route.Feature, beforeEnter: (to,from,next) => enterIf(to,from,next,()=>{ return useAuthorizationStore().canViewDevTools})},
                    {...Route.SavedEnvironments, beforeEnter: checkSavedEnvironmentsAccess },
                    {...Route.MySavedEnvironments, beforeEnter: checkSavedEnvironmentsAccess },
                    {...Route.SavedEnvironment, beforeEnter: checkSavedEnvironmentsAccess },
                    {...Route.MySavedEnvironment, beforeEnter: checkSavedEnvironmentsAccess },                ]
            },
            {
                ...Route.ExerciseAccessPoint,
                beforeEnter: multiguard([requireAuth, newUIClaimCheck]),
            },
            {
                path: Route.Todo.path,
                component: Page,
                beforeEnter: multiguard([requireAuth, newUIClaimCheck]),
                children: [
                    Route.Todo
                ]
            },
            {
                path: Route.Courseware.path,
                component: Page,
                beforeEnter: multiguard([requireAuth, ssoToCourseware])
            },
            {
                ...Route.PilotUnauthorized
            },
            {
                path: "/invitations",
                name: "invitationNoId",
                beforeEnter: (to, from, next) => {
                    window.location.assign(new URL(location.pathname, Config.LOGIN_UI_BASE_URL));
                },
                component: Page
            },
            {
                path: "/invitations/:invitationId",
                name: "invitation",
                beforeEnter: (to, from, next) => {
                    window.location.assign(new URL(location.pathname, Config.LOGIN_UI_BASE_URL));
                },
                component: Page
            },
            {
                path: "/shared-environment-invitation/:catalogFamilyAccessId",
                name: "sharedEnvironmentInvitation",
                beforeEnter: (to, from, next) => {
                    window.location.assign(new URL(location.pathname, Config.LOGIN_UI_BASE_URL));
                },
                component: Page
            },
            {
                path: "/signup",
                name: "Signup",
                beforeEnter: (to) => { window.location.assign(new URL(to.fullPath, Config.LOGIN_UI_BASE_URL)) },
                component: Page
            },
            {
                ...Route.Registration
            },
            { path: "/:catchAll(.*)", redirect: Route.Home }
        ]

        /**
         * Set route.meta.title to route.name. 
         * The value is NOT for web browsers but for analytics.
         * For web browser's page title, see src/composables/usePageTitle.ts
         */
        const setRouteMetaTitle = (route:RouteRecordRaw) =>
        {
            route.meta ??= {};

            route.meta.title = route.meta.title || route.name;

            route.children?.forEach(setRouteMetaTitle);
        }

        routes.forEach(setRouteMetaTitle);

        return routes;
    }
}
export default Route